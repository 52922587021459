import React from "react"

import Layout from "../components/theme/layout"
import SEO from "../components/theme/seo"
import Onecol from '../components/onecol'
import Twocol from '../components/twocol'
import Cards from '../components/cards'
import ImageText from '../components/imagetext'
import ImageCTA from '../components/imageCTA'
import ButtonCTA from '../components/buttonCTA'
import { graphql } from 'gatsby'

import Banner from '../components/banner'

import WebBanner from '../images/web-services-banner.jpg'

import { LazyLoadImage } from 'react-lazy-load-image-component';
import imac from '../images/website-imac.png'
import Phone from '../images/website-mobile.jpg'
import QR from '../images/qr-code-phone.jpg'

import { Edit } from 'tabler-icons-react'
import { MessageCircle } from 'tabler-icons-react'
import { Copyright } from 'tabler-icons-react'
import { Code } from 'tabler-icons-react'
import { Search } from 'tabler-icons-react'

export default function Web({data}) {
  
    return (
      <Layout>
         <SEO 
        title="Web & Digital Services" description="Need a website refresh or a brand-new website? We offer quality web development services that will get your brand noticed."
    />

        <Banner
          title="Web & Digital Services"
          description="We provide custom, professional websites and digital services that deliver real results and put you ahead of your competitors – without breaking the bank."
          img={WebBanner}
        />
    
        {data.allStrapiWebDevelopment.edges.map(({ node }) => (
          
          <>
        <Onecol
            key={node.web_intro.id}
            title={node.web_intro.one_col_title}
            description={node.web_intro.one_col_description}
        />

        <Cards
        key={node.web_page_cards.id} 
        linkOne="/web/blogs"
        iconOne={
            <Edit
                size={40}
                strokeWidth={2}
                color={'#DD2E39'}
            />            
        }
        titleOne={node.web_page_cards.title_one}
        descriptionOne={node.web_page_cards.description_one}
        linkTwo="/web/social-media"
        iconTwo={
            <MessageCircle
                size={40}
                strokeWidth={2}
                color={'#DD2E39'}
            />            
        }
        titleTwo={node.web_page_cards.title_two}
        descriptionTwo={node.web_page_cards.description_two}
        linkThree="/web/copywriting"
        iconThree={
            <Copyright
                size={40}
                strokeWidth={2}
                color={'#DD2E39'}
            />            
        }
        titleThree={node.web_page_cards.title_three}
        descriptionThree={node.web_page_cards.description_3}
        />      

        <ButtonCTA />

    <Twocol
        key={node.web_two_col.id}
        image={imac}
        grey={true}
        textLeft={true}
        intro={node.web_two_col.two_col_intro}
        iconOne={
            <Code
                size={40}
                strokeWidth={2}
                color={'#DD2E39'}
            /> 
        }
        titleOne={node.web_two_col.title_one}
        linkOne="/web/web-development"
        descriptionOne={node.web_two_col.description_one}
        iconTwo={
            <Search
                size={40}
                strokeWidth={2}
                color={'#DD2E39'}
            />            
        }
        titleTwo={node.web_two_col.title_two}
        linkTwo="/web/seo"
        descriptionTwo={node.web_two_col.description_two}
        />

    <ImageText 
        key={node.web_two_col_cta.id}
        textLeft={false}
        background={Phone}
        title={node.web_two_col_cta.promo_intro}
        description={node.web_two_col_cta.promo_description}
        buttonLink="https://www.element6.io/"
        buttonTitle="visit element6.io"
    />

    <ImageCTA
        title="Enhance your customer’s experience with QR codes."
        image={QR}
        link="/contact-us"
        linkText="inquire today"
    />
          </>
        ))}  
      </Layout>
    )
    
  }

// export default Web

export const query = graphql`
query Web {
  allStrapiWebDevelopment {
    edges {
      node {
        web_hero {
          id
          hero_title
          hero_description
        }
        web_intro {
          id
          one_col_title
          one_col_description
        }
        web_page_cards {
          id
          title_one
          description_one
          title_two
          description_two
          title_three
          description_3
        }
        web_two_col {
          id
          two_col_intro
          title_one
          description_one
          title_two
          description_two
        }
        web_two_col_cta {
          id
          promo_intro
          promo_description
          promo_button
        }
      }
    }
  }
}
  
`