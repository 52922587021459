import React from "react"
import { Container, Grid, Button } from "@material-ui/core"
import { LazyLoadImage } from "react-lazy-load-image-component"

export default function ImageText(props) {
  return (
    <Container className="mb-40">
      <Grid
        container
        className={`twocol-list flex items-center ${
          props.textLeft === false ? "" : "flex-row-reverse"
        }`}
      >
        <Grid md={6} sm={12} className="text-right">
          <LazyLoadImage src={props.background} effect="blur" />
        </Grid>
        <Grid md={6} sm={12}>
          <h3>{props.title}</h3>
          <p className="md:w-3/4">{props.description}</p>
          {props.buttonLink ? (
            <a href={props.buttonLink} target="_blank" rel="noreferrer">
              <Button variant="outlined" color="primary" className="mt-20">
                {props.buttonTitle}
              </Button>
            </a>
          ) : null}
        </Grid>
      </Grid>
    </Container>
  )
}
